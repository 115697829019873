import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Skill Practice: Rope Climbs`}</p>
    <p>{`then,`}</p>
    <p>{`4 Rounds for time of:`}</p>
    <p>{`1-Rope Climb (RX+=legless)`}</p>
    <p>{`2-Wall Walks`}</p>
    <p>{`3-Box jumps (30/24, RX+ 36/30)`}</p>
    <p>{`4-Muscle Ups`}</p>
    <p>{`50-Double Unders`}</p>
    <p>{`100ft Bear Crawl`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The CrossFit Open starts February 23rd! Sign up now at:
Games.crossfit.com.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      